import { buildThemeAliases } from "utils"

export const baseLineHeights = {
  oneThirtyPercent: "130%",
  oneSixtyPercent: "160%"
}

const baseAliases = buildThemeAliases(baseLineHeights, {
  header: "oneThirtyPercent",
  primary: "oneSixtyPercent",
  footer: "oneSixtyPercent"
})

export const base = {
  ...baseLineHeights,
  ...baseAliases,
}

export type Base = Record<keyof typeof base, string>
