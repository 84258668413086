import { rem } from "polished"

export const base = {
  "13": rem("13px"),
  "16": rem("16px"),
  "18": rem("18px"),
  "20": rem("20px"),
  "22": rem("22px"),
  "26": rem("26px"),
  "32": rem("32px"),
  "50": rem("50px")
}

export type Base = Record<keyof typeof base, string>
