import { buildThemeAliases } from "utils"
import { base } from "./base"

export const baseTextAliases = buildThemeAliases(base, {
  button: "16",
  footer: "18",
  footerMobile: "13",
  eyebrow: "20",
  header: "50",
  headerMobile: "26",
  primary: "18",
  primaryMobile: "16",
  title: "32",
  titleMobile: "22"
})

export type TextAliases = Record<keyof typeof baseTextAliases, string>