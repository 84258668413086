import { buildThemeAliases } from "utils"

const baseFontWeights = {
  "300": 300,
  "400": 400,
  "800": 800
}

const baseAliases = buildThemeAliases(baseFontWeights, {
  bold: "800",
  light: "300",
  regular: "400"
})

export const base = {
  ...baseFontWeights,
  ...baseAliases,
}

export type Base = Record<keyof typeof base, number>
