import { useContext } from "react"
import { ThemeContext } from "styled-components"

import { ThemeProps } from "theme"

const useTheme = (): ThemeProps => {
  return useContext<ThemeProps>(ThemeContext)
}

export default useTheme
