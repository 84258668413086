import React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import { ThemeProvider } from "styled-components";

import BreakpointsProvider from "components/shared/BreakpointsProvider";
import theme from "theme";

interface OwnProps {
  element: React.ReactElement;
}

type GatsbyBrowserProps = OwnProps;

export const wrapRootElement: React.FC<GatsbyBrowserProps> = ({
  element,
}): React.ReactElement => (
  <ThemeProvider theme={theme}>
    <BreakpointsProvider>
      <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
    </BreakpointsProvider>
  </ThemeProvider>
);
