import { buildThemeAliases } from "utils"

export const baseColors = {
  black: "#000000",
  coral: "#F2AA6A",
  fog: "#ECF1EB",
  indigo: "#091357",
  navy: "#0B1D2D",
  sea: "#1C4957",
  white: "#FFFFFF",
  wisteria: "#C7C7FA"
}

const baseAliases = buildThemeAliases(baseColors, {
  linkPrimary: "black",
  textPrimary: "black",
  textFooter: "sea"
})

export const base = {
  ...baseColors,
  ...baseAliases,
}

export type Base = Record<keyof typeof base, string>
