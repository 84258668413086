import { Breakpoints, breakpoints } from "./breakpoints"
import { Colors, colors } from "./colors"
import { Fonts, fonts } from "./fonts"
import { FontSizes, fontSizes } from "./fontSizes"
import { FontWeights, fontWeights } from "./fontWeights"
import { LetterSpacings, letterSpacings } from "./letterSpacing"
import { LineHeights, lineHeights } from "./lineHeights"
import { Sizes, sizes } from "./sizes"

export interface ThemeProps {
  breakpoints: Breakpoints,
  colors: Colors,
  fonts: Fonts,
  fontSizes: FontSizes,
  fontWeights: FontWeights,
  letterSpacings: LetterSpacings,
  lineHeights: LineHeights,
  sizes: Sizes
}

const theme: ThemeProps = {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  sizes
}

export default theme
