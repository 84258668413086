import { Base, base } from "./base"
import { TextAliases, baseTextAliases } from "./textAliases"

export interface FontSizes extends Base {
  text: TextAliases
}

export const fontSizes: FontSizes = {
  ...base,
  text: baseTextAliases,
}
