export const base = {
  contentWidth: "1140px",
  footerIconDesktop: "84px",
  footerIconMobile: "50px",
  iconDesktop: "153px",
  iconMobile: "100px",
  maxWidth: "1440px"
}

export type Base = Record<keyof typeof base, string>
