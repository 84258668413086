// Any changes made here should be made in src/utils/linkResolver.js
// Due to importing issue

import { LinkResolver } from "prismic-reactjs"

const linkResolver: LinkResolver = (doc) => {
  if (doc.type !== "homepage") {
    return `/${doc.uid}`
  } else {
    return `/`
  }
}

export default linkResolver;
